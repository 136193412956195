import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class BootstrapService {

  private widthSubject: Subject<number> = new Subject<number>();
  width: Observable<number> =  this.widthSubject.asObservable();

  constructor() { }

  calculateBootstrapWidth(): number {
    let viewportWidth: number = window.innerWidth;
    let widthValue: number = 0;

    if (viewportWidth >= 1200) {
        widthValue = 5;
    }
    else if (viewportWidth >= 992) {
        widthValue = 4;
    }
    else if (viewportWidth >= 768) {
        widthValue = 3;
    }
    else if (viewportWidth >= 576) {
        widthValue = 2;
    }
    else if (viewportWidth < 576) {
        widthValue = 1;
    }

    return widthValue;
  }

  setWidth(width: number): void {
    this.widthSubject.next(width);
  }
}
