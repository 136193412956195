import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-review-item',
  templateUrl: './review-item.component.html',
  styleUrls: ['./review-item.component.css']
})
export class ReviewItemComponent implements OnInit {

  @Input() text: string = '';
  @Input() name: string = '';

  quoteFontSize: string = '1rem';
  textFontSize: string = '1rem';
  nameFontSize: string = '1rem';


  constructor() { }

  ngOnInit(): void {
  }

}
