import { Component, OnInit } from '@angular/core';
import { Review } from '../review';

@Component({
  selector: 'app-reviews',
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.css']
})
export class ReviewsComponent implements OnInit {

  reviews: Review[] = [
    {
      text: 'Fantastic work, fair price, very polite. A gentleman of the trade!',
      name: 'Tony, Wilford Village'
    },
    {
      text: 'What a delight to have honest, polite, well mannered professionals to do my job.',
      name: 'Susan'
    },
    {
      text: 'I couldn\'t recommend highly enough. Already considering using Abacus for another job.',
      name: 'J'
    }
  ]
  constructor() { }

  ngOnInit(): void {
  }

}
