import { Component, OnInit } from '@angular/core';
import { BootstrapService } from '../bootstrap.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  imageCss: any = {
    width: '',
    height: ''
  };

  constructor(private bootstrapService: BootstrapService) { }

  ngOnInit(): void {

    this.bootstrapService.width.subscribe(newWidth => {
      this.imageCss = this.calculateImageCss(newWidth);
    });

    this.imageCss = this.calculateImageCss(this.bootstrapService.calculateBootstrapWidth());
  }

  calculateImageCss(bootstrapWidth: number): object {
    console.log('calculating');
    return {
      width: bootstrapWidth < 3 ? '100%' : 'auto',
      height: bootstrapWidth < 3 ? 'auto' : '25vh'
    }
  }

}
