import { Component, OnInit, Input } from '@angular/core';
import { BootstrapService } from '../bootstrap.service';

@Component({
  selector: 'app-imagegallery',
  templateUrl: './imagegallery.component.html',
  styleUrls: ['./imagegallery.component.css']
})
export class ImagegalleryComponent implements OnInit {

  @Input() componentHeight: string = '500px';

  galleryFiles: string[] = [
    'image1.jpg',
    'image2.jpg',
    'image3.jpg',
    'image4.jpg',
    'image5.jpg'
  ];

  galleryImages: string[] = [];

  currentImage: string = '';

  currentIndex: number = 0;

  bootstrapWidth: number = 0;

  constructor(private bootstrapService: BootstrapService) { }

  ngOnInit(): void {
    for(let i = 0; i < this.galleryFiles.length; i++) {
      this.galleryImages.push(`url(../../assets/gallery/${this.galleryFiles[i]})`)
    }

    this.currentImage = this.galleryImages[0];

    //Update boostrapWidth when the window is resized
    this.bootstrapService.width.subscribe( newWidth => {
      this.bootstrapWidth = newWidth;
    })
  }

  nextImage(): void {
    let nextIndex: number = this.currentIndex + 1 > (this.galleryImages.length - 1) ? 0 : this.currentIndex + 1;
    
    this.currentImage = `${this.galleryImages[nextIndex]}`;
    this.currentIndex = nextIndex;
  }

  previousImage(): void {
    let nextIndex: number = this.currentIndex - 1 < 0 ? this.galleryImages.length - 1 : this.currentIndex - 1;

    this.currentImage = this.galleryImages[nextIndex];

    this.currentIndex = nextIndex;
  }

}
