<!-- <div class="container mb-5">
    <div class="row">
        <div class="col-12 col-md-6">
            <img src="../../assets/roof-vertical-resized2.jpg" height="500" class="w-100" />
        </div>

        <div class="col-12 col-md-6 flex-grow-1 my-3 d-flex align-items-center">

            <div class="d-flex flex-column justify-content-center">
                <div *ngFor="let service of services; let i = index" class="flex-grow-1 ">
                    <div>
                        <div class="text-center">{{service.text}}</div>
                        <div *ngIf="i != services.length - 1" class="my-4" style="background-color:lightgray; height: 1px; width: 100%"></div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div> -->


<div class="services-root" [style.fontSize]="fontSize">
    <div class="container h-100">
        <div class="row h-100">
            <div class="col-12 col-md-6 flex-grow-1 my-auto d-flex justify-content-center align-items-center p-3 text-box">
                <div class="d-flex flex-column justify-content-center">
                    <div *ngFor="let service of services; let i = index" class="flex-grow-1 ">
                        <div>
                            <div class="text-center">{{service}}</div>
                            <div *ngIf="i != services.length - 1" class="my-4" style="background-color:lightgray; height: 1px; width: 100%"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>