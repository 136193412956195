<!-- <div class="w-100" [style.backgroundImage]="currentImage" [sytle.height]> -->
<div class="p-4 imagegallery-root-background" style="background-color: #333333;">
    <div class="w-100 imagegallery-root-foreground" [style]="{height: componentHeight}">
        <div class="row" style="height: 90%;">
            <!-- <div class="col-2 d-flex justify-content-start align-items-center">
                <button class="nav-button" (click)="previousImage()">&lt;</button>
            </div> -->
            
            <div class="col-12 col-md-12 image h-100" [style]="{'background-image': currentImage, height: componentHeight}"></div>
            
            <!-- <div class="col-2 d-flex justify-content-end align-items-center">
                <button class="nav-button" (click)="nextImage()">&gt;</button>
            </div> -->
        </div>
        
        <div class="row" style="height: 10%;">
            <div class="col-0 col-md-3"></div>
            <div class="col-6 col-md-3 d-flex justify-content-start align-items-center">
                <button class="nav-button" (click)="previousImage()">&lt;</button>
            </div>
            <div class="col-6 col-md-3 d-flex justify-content-end align-items-center">
                <button class="nav-button" (click)="nextImage()">&gt;</button>
            </div>
            <div class="col-0 col-md-3"></div>
        </div>
    </div>
</div>