import { Component, OnInit } from '@angular/core';
import { BootstrapService } from '../bootstrap.service';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css']
})
export class ServicesComponent implements OnInit {

  services: string[] = [
  'Fully qualified slater and tiler including heritage work. 20 years experience. Fully insured',
  'All roofing maintenance and re-roofs. Lead work, chimney work, guttering, valleys, hips and ridges',
  'Free quotations, roof reports and general advice available'
  ]

  fontSize: string = this.calculateFontSize(this.bootstrapService.calculateBootstrapWidth());

  constructor(private bootstrapService: BootstrapService) { }

  
  bootstrapWidthUpdated(newWidth: number): void {
    this.fontSize = this.calculateFontSize(newWidth);
  }
  
  ngOnInit(): void {

    this.bootstrapService.width.subscribe(newWidth => this.bootstrapWidthUpdated(newWidth));
  }

  calculateFontSize(bootstrapWidth: number) {
    return bootstrapWidth < 3 ? '1.15rem' : '1.75rem';
  }
}
