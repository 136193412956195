import { Component } from '@angular/core';
import { BootstrapService } from './bootstrap.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Abacus Roofing Nottingham';

  constructor(private bootstrap: BootstrapService) {}

  ngOnInit() {
    window.addEventListener('resize', () => {
      console.log('resizing');
      this.bootstrap.setWidth(this.bootstrap.calculateBootstrapWidth());
    });
  }
}
