<div class="p-4" style="background-color: #333333; color: white;">
    <div class="text-center text-uppercase" style="font-weight: 700; font-size: 1.25rem;">Contact</div>
    <br />
    <!-- <div class="row">
        <div class="col-0 col-md-3"></div>
        <div class="col-12 col-md-3 mb-3 mb-md-0">30 Hartcroft Road<br />Bestwood Park<br />Nottingham<br />NG55JF</div>
        <div class="col-12 col-md-3">
            <div>T: 123465789</div>
            <div>E: contact@abacusroofing.co.uk</div>
        </div>
        <div class="col-0 col-md-3"></div>
    </div> -->

    <div class="row">
        <!-- <div class="col-0 col-md-3"></div> -->
        <!-- <div class="col-12 col-md-6 mb-3 mb-md-0"> -->
            <div class="col-12" style="display: flex; flex-direction: row; justify-content: space-around; align-items: flex-start;">
                <div>
                    <div>
                        30 Hartcroft Road
                    </div>
    
                    <div>
                        Bestwood Park
                    </div>
    
                    <div>
                        Nottingham
                    </div>
    
                    <div>
                        NG55JF
                    </div>
                </div>

                <div>
                    <div>T: 07833 653711</div>
                    <!-- <div>E: contact@abacusroofing.co.uk</div> -->
                </div>
            </div>
            <!-- </div> -->
        <!-- <div class="col-0 col-md-3"></div> -->

    </div>
</div>
